import * as mutationTypes from "@/store/mutations-types";
import * as userApi from "@/api/user";

export const user = {
    namespaced: true,
    state: () => ({
        /**
         * 分页内容
         */
        pageData: {
            /**
             * 内容列表
             */
            records: [],
            /**
             * 列表总数
             */
            total: 0,
            /**
             * 每页列表数
             */
            size: 20,
            /**
             * 当前页码
             */
            current: 1,
            /**
             * 页码总数
             */
            pages: 0,
        },
    }),
    mutations: {
        [mutationTypes.SET_USER_PAGE]: (state, {pageData}) => {
            state.pageData = pageData;
        },
    },
    getters: {
        records: state => state.pageData?.records ?? [],
    },
    actions: {
        page: ({commit}, {search, page: {current, size}}) => {
            return userApi.page(search, {current, size})
                .then(r => {
                    commit(mutationTypes.SET_USER_PAGE, {pageData: r.data});
                    return r.data;
                });
        },
    },
};
