import Vue from "vue";
import Vuex from 'vuex';
import * as mutationTypes from './mutations-types';
import {LOGOUT} from './mutations-types';
import * as actionTypes from "@/store/actions-types";
import {editProfile, getProfile} from "@/api/profile";
import {login, logout} from "@/api/auth";
import {sysDict} from "@/store/modules/sys-dict";
import {user} from "@/store/modules/user";
import {article} from "@/store/modules/article";
import {admin} from "@/store/modules/admin";
import {navMenu} from "@/store/modules/nav-menu";
import {feedback} from "@/store/modules/feedback";
import {routers} from "@/store/modules/router";
import createPersistedState  from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        navMenu,
        admin,
        sysDict,
        user,
        article,
        feedback,
        routers
    },
    state: () => ({
        loginStatus: false,
        failedRequests: 0,
        profile: {},
    }),
    mutations: {
        [mutationTypes.SET_LOGIN_STATUS]: (state, {status}) => {
            state.loginStatus = status;
        },
        [mutationTypes.REQUEST_FAILED]: (state) => {
            state.failedRequests++;
        },
        [mutationTypes.SET_PROFILE]: (state, {profile}) => {
            state.profile = profile;
        },
        [mutationTypes.LOGOUT]: (state) => {
            state.loginStatus = false;
            state.profile = null;
            location.href = process.env.BASE_URL + 'login';
            // router.push(process.env.BASE_URL + '/login');
        },
    },
    actions: {
        /**
         * 登录
         */
        doLogin: ({commit}, payload) => {
            return login(payload).then((data) => {
                console.log("payload",payload,"data",data)
                commit("routers/routers",data.data.data)
                commit(mutationTypes.SET_LOGIN_STATUS, {status: true});
            });
        },
        /**
         * 退出
         */
        doLogout: ({commit}) => {
            logout().then(() => {
                commit(LOGOUT);
            });
        },
        /**
         * ajax 请求发送失败，没有收到响应
         */
        [actionTypes.REQUEST_FAILED]: ({commit}) => {
            commit(mutationTypes.REQUEST_FAILED);
        },
        /**
         * 刷新，获取当前用户信息
         */
        [actionTypes.DO_REFRESH_PROFILE]: ({commit}) =>
            getProfile()
                .then(r => {
                    r.data.avatar && (r.data.avatarUrl = '/api/asset/file/' + r.data.avatar);
                    commit(mutationTypes.SET_LOGIN_STATUS, {status: true});
                    commit(mutationTypes.SET_PROFILE, {profile: r.data});
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        commit(mutationTypes.SET_LOGIN_STATUS, {status: false});
                        // router.push("/login");
                    }
                    return error;
                }),
        /**
         * 编辑个人资料
         */
        [actionTypes.EDIT_PROFILE]: ({dispatch}, profileFormData) => {
            return editProfile(profileFormData)
                .then(() => dispatch(actionTypes.DO_REFRESH_PROFILE));
        },
    },
    plugins:[
        createPersistedState({
            key: 'routersData',
            paths: ["routers.routers"]
        })
    ]
});
