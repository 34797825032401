import axios from 'axios';
import {stringify} from 'qs';

/**
 * 刷新验证码
 */
export const refreshCaptcha = () => axios.get('/auth/captcha', {
    baseURL: '/api',
    responseType: 'blob',
});

/**
 * 登入
 */
export const login = ({username, password, captcha, rememberMe}) =>
    axios.post('/auth/do-login', stringify({
        username, password, captcha, 'remember-me': rememberMe
    }), {
        baseURL: '/api'
    })
        .then(r => {
            return r;
        });

/**
 * 登出
 */
export const logout = () => axios.post('/auth/do-logout', null, {
    baseURL: '/api'
});

/**
 * 获取用户的个人信息(为了获取地市字段)
 */
export const getCurrentUserInfo = () => axios.get('/api/auth/getCurrentUserInfo');
