/**
 * 递归搜索，按 id 搜索。用途包括按上级 id 搜索上级项。
 */
export const recursiveSearch = (arr, searchId) => {
    const innerSearch = function (arr) {
        let res;
        if ((res = arr.find(ele => ele.id === searchId)) !== undefined) {
            return res;
        } else {
            for (let o of arr.filter(ele => ele.children?.length)) {
                if ((res = innerSearch(o.children))) {
                    return res;
                }
            }
        }
    };
    return innerSearch(arr);
};

export const recursiveSearchBy = (arr, field, search) => {
    const innerSearch = function (arr) {
        let res;

        if ((res = arr.find(ele => ele[field] === search)) !== undefined) {
            return res;
        } else {
            for (let o of arr.filter(ele => ele.children?.length)) {
                if ((res = innerSearch(o.children))) {
                    return res;
                }
            }
        }
    };

    return innerSearch(arr);
};

/**
 * 递归搜索，按 id 搜索，包括在内的所有上级的id。用于按上级 id 级联选中。
 */
export const recursiveSearchSupIds = (arr, searchId) => {
    const innerSearch = function (arr) {
        let res;

        if ((res = arr.find(ele => ele.id === searchId)) !== undefined) {
            return res.id;
        } else {
            for (let o of arr.filter(ele => ele.children?.length)) {
                if ((res = innerSearch(o.children))) {
                    return [o.id, ...res];
                }
            }
        }
    };

    return innerSearch(arr);
};

export const recursiveSearchSupIdsBy = (arr, field, search) => {
    const innerSearch = function (arr) {
        let res;

        if ((res = arr.find(ele => ele[field] === search)) !== undefined) {
            return [res.id];
        } else {
            for (let o of arr.filter(ele => ele.children?.length)) {
                if ((res = innerSearch(o.children))) {
                    return [o.id, ...res];
                }
            }
        }
    };

    return innerSearch(arr);
};

export const recursiveSearchSupItemsBy = (arr, field, search) => {
    const innerSearch = function (arr) {
        let res;

        if ((res = arr.find(ele => ele[field] === search)) !== undefined) {
            return [res];
        } else {
            for (let o of arr.filter(ele => ele.children?.length)) {
                if ((res = innerSearch(o.children))) {
                    return [o, ...res];
                }
            }
        }
    };

    return innerSearch(arr);
};

/**
 * 递归搜索，按 id 搜索，包括在内的所有下级。用于级联选中下级。
 */
export const recursiveSearchSubIds = (arr, searchId) => {
    let item = recursiveSearch(arr, searchId);
    let supIds = [];

    const collectSubIds = function (arr) {
        let ids = arr.map(ele => ele.id);
        arr.filter(ele => ele?.children?.length)
            .forEach(ele => {
                ids.push(...collectSubIds(ele.children));
            });

        return ids;
    };
    if (item?.children?.length) {
        supIds = collectSubIds(item.children);
    }

    return [searchId, ...supIds];
};

/**
 * 从树里递归移除指定项。
 */
export const recursiveRemove = (arr, selectedIds) => {
    const innerRemove = arr => {
        let deleteItems = [];

        arr.forEach(ele => {
            if (selectedIds.includes(ele.id)) {
                deleteItems.push(ele);
            } else if (ele.children?.length) {
                innerRemove(ele.children);
            }
        });

        deleteItems.forEach(ele => {
            arr.splice(arr.indexOf(ele), 1);
        });
    };

    innerRemove(arr);
};

/**
 * 收集所有分支节点的 id，用于树的全部展开，级联选择所有下级
 */
export const collectParentIds = function (arr) {
    let ids = [];
    for (const ele of arr) {
        if (ele.children?.length) {
            ids.push(ele.id, ...collectParentIds(ele.children));
        }
    }

    return ids;
};

export const storedPath2Src = storedPath => {
    return typeof storedPath === 'string' ? ('/api/asset/file/' + encodeURI(storedPath)) : undefined;
};

/**
 * 变换字典项的合集字典结构，为页面列表的 text、value 数组的合集字典的结构
 */
export const transformSysDict = sysDict => {
    const ret = {};
    let options;
    for (const item in sysDict) {
        options = [];
        for (const k in sysDict[item]) {
            options.push({value: k, text: sysDict[item][k]});
        }
        ret[item] = options;
    }

    return ret;

};
