import instance from "@/api/common";
import { stringify } from "qs";

/**
 * 检查名称是否合法
 */
export const checkUsername = (username, signal) =>
  instance.get("/user/check-username", {
    signal: signal,
    params: { username },
  });

export const checkUsernameId = (username, userId) =>
  instance.get("/user/check-username", {
    params: { username, userId },
  });
/**
 * 新增。
 * 返回 r.headers.location
 */
export const create = (entity) => instance.post("/user", entity);

/**
 * 删除单个
 */
export const deleteById = (id) => instance.delete(`/user/${id}`);

/**
 * 删除多个
 */
export const deleteByIds = (ids) => instance.delete("/user", { data: ids });

/**
 * 更新单个
 */
export const editById = (id, entity) => instance.patch(`/user/${id}`, entity);

export const updatePassword = (id, username, password) =>
  instance.post("/user/updatePassword", {
    id: id,
    username: username,
    password: password,
  });

/**
 * 分页查询
 */
export const page = (search, pager) => {
  return instance.get("/user", {
    params: { ...search, ...pager },
    paramsSerializer: stringify,
  });
};

/**
 * 获取单个
 */
export const getById = (id) => instance.get(`/user/${id}`);

export const saveUserGroups = (userId, groupIds) =>
  instance.put(`/user/${userId}/gorups`, groupIds);

/**
 * 按照用户 id，列出所属组的 id
 */
export const listGroupIdsById = (id) => instance.get(`/user/${id}/groups/ids`);

export const searchByNameOrId = (name, id) =>
  instance.get("/user/search_patient", { params: { name: name, id: id } });
