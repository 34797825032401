import * as mutationTypes from "@/store/mutations-types";

export const feedback = {
    namespaced: true,
    state: () => ({
        search: {},
        pageOptions: null,
    }),
    mutations: {
        [mutationTypes.SET_FEEDBACK_SEARCH]: (state, {search}) => {
            state.search = {...search};
        },
        [mutationTypes.SET_FEEDBACK_PAGE_OPTIONS]: (state, {pageOptions}) => {
            state.pageOptions = {...pageOptions};
        },
    }
};
