// 登录退出
export const REQUEST_FAILED = 'REQUEST_FAILED';

// 用户资料
export const DO_REFRESH_PROFILE = 'DO_REFRESH_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';

// 系统字典
export const SYS_DICT_CREATE = 'SYS_DICT_CREATE';
export const SYS_DICT_DELETE_BY_ID = 'SYS_DICT_DELETE_BY_ID';
export const SYS_DICT_DELETE = 'SYS_DICT_DELETE';
export const SYS_DICT_EDIT = 'SYS_DICT_EDIT';
export const SYS_DICT_PAGE = 'SYS_DICT_PAGE';
export const SYS_DICT_BY_ID = 'SYS_DICT_BY_ID';

// 用户管理
export const USER_CREATE = 'USER_CREATE';
export const USER_DELETE_BY_ID = 'USER_DELETE_BY_ID';
export const USER_DELETE = 'USER_DELETE';
export const USER_EDIT = 'USER_EDIT';
export const USER_PAGE = 'USER_PAGE';
export const USER_BY_ID = 'USER_BY_ID';
