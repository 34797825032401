import Vue from "vue";
import VueI18n from 'vue-i18n';
import zh_CN from './zh_CN';

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'zh_CN',
    fallbackLocale: 'zh_CN',
    messages: {zh_CN: zh_CN},
});
