import LoadingSkeleton from "@/views/Common/LoadingSkeleton.vue";
import store from "@/store";
import * as actionTypes from "@/store/actions-types";

export const staticRoutes = [{
    path: '/login',
    component: () => ({
        component: import ('@/views/Common/AppLogin'),
        loading: LoadingSkeleton,
        delay: 20,
    }),
    meta: {
        requiresAuth: false
    },
    name: 'login',
    props: true,
    beforeEnter(to, from, next) {
        to.params.fromName = from.name;
        next();
    },
},
    {
        path: '/',
        component: () => ({
            component: import ('@/views/Common/Admin'),
            loading: LoadingSkeleton,
            delay: 20,
        }),
        beforeEnter(to, from, next) {
            console.log("进入静态路由beforeEnter")

            if (store.state.loginStatus) {
                next();
            } else {
                store.dispatch(actionTypes.DO_REFRESH_PROFILE)
                    .then(() => {
                        next();
                    });
            }
        },
        children: [
            {
                path: "",
                name:''
            },
        ]
    }]