// import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from "vuetify/lib/locale/zh-Hans";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        // iconfont: 'faSvg',
        // iconfont: 'fa',
    },
    lang: {
        locales: {zhHans},
        current: 'zhHans',
    },
    theme: {
        themes: {
            light: {
                // primary: '#e86666',
                'hosp-pink1': '#e86666',
                'hosp-pink2': '#ef8c8a',
                'hosp-pink3': '#fbe6e3',
                'hosp-grey1': '#6b6665',
                'hosp-grey2': '#a1a1a1',
                'hosp-grey3': '#dcdddd',
            },
        },
    }
});
