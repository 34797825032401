import axios from "axios";
import store from "@/store";
import * as actionTypes from "@/store/actions-types";
import { LOGOUT } from "@/store/mutations-types";
import { Message } from "element-ui";

let abortController = new AbortController();
const instance = axios.create({
  baseURL: "/api",
  signal: abortController.signal,
});

const refreshAbortController = () => {
  abortController = new AbortController();
  instance.defaults.signal = abortController.signal;
};

/*
instance.interceptors.request.use(config => {
    config.headers['X-Auth-Token'] = store.state.authToken;
    config.headers['X-CSRF-Token'] = store.state.csrfToken;
    return config;
});
*/

instance.interceptors.response.use(null, function (error) {
  if (error.response) {
    // 响应状态码范围不在 2xx
    if (error.response?.status === 401) {
      abortController.abort();
      store.commit(LOGOUT);
      refreshAbortController();
    } else {
      Message.error("请求失败");
    }
  } else if (error.request) {
    // 没有收到响应
    store.dispatch(actionTypes.REQUEST_FAILED);
  } else {
    // 配置内容错误
  }

  return Promise.reject(error);
});

export default instance;
