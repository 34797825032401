import store from '@/store/index';
import {stringify} from "qs";
import instance from '@/api/common';

/**
 * 获取当前用户资料
 */
export const getProfile = () => instance.get('/profile');

export const editProfile = profileFormData => instance.put('/profile',
    profileFormData,
    {
        // headers: {'Content-Type': 'multipart/form-data',},
        onUploadProgress: function (/*progressEvent*/) {
            // let percent = progressEvent.total > 0
            //     ? Math.round(progressEvent.loaded * 1000 / progressEvent.total) / 10
            //     : 100;
            // progress(percent);
        }
    });

/**
 * 更改密码
 */
export const changePassword = (oldPassword, newPassword) => instance.post('/profile/change-password', stringify({
    oldPassword: oldPassword,
    newPassword: newPassword
}))
    .then(() => store.dispatch('doLogout'));
