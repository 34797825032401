import Vue from "vue";
import router from "@/router/index";
import store from "@/store/index";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "@mdi/font/css/materialdesignicons.css";

import preview from "vue-photo-preview";
import "vue-photo-preview/dist/vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/common.css";

import * as echarts from "echarts";

import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
    GeoComponent
} from 'echarts/components';
import {
    MapChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';
// 添加富文本框

echarts.use(
    [TitleComponent, ToolboxComponent, TooltipComponent, VisualMapComponent, GeoComponent, MapChart, CanvasRenderer]
);
// 引入echarts
// import echarts from "echarts";

// Vue.prototype.$echarts = echarts;

import VueCookie from 'vue-cookie'

Vue.use(VueCookie)  // Vue.prototype给Vue自定义个属性全局里有Vue就能用自定义属性    Vue.use()挂在到全局

Vue.use(ElementUI);

Vue.use(preview);
// 富文本跑【配置
// import VueFroala from 'vue-froala-wysiwyg'
// import jQuery from 'jquery';
//
// require('froala-editor/js/froala_editor.pkgd.min');
// require('froala-editor/css/froala_editor.pkgd.min.css');
// require('font-awesome/css/font-awesome.css');
// require('/src/utils/editor_zh_cn');
// require('froala-editor/css/froala_style.min.css');
// window.$ = jQuery
// window.jQuery = jQuery
// Vue.use(VueFroala);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    icons: {
        iconfont: "mdi",
    },
}).$mount("#app");



