import * as mutationTypes from "@/store/mutations-types";

export const sysDict = {
    state: () => ({
        /**
         * 分页内容
         */
        page: {
            /**
             * 内容列表
             */
            records: [],
            /**
             * 列表总数
             */
            total: 0,
            /**
             * 每页列表数
             */
            size: 20,
            /**
             * 当前页码
             */
            current: 1,
            /**
             * 页码总数
             */
            pages: 0,
        },
        tree: [],
    }),
    mutations: {
        [mutationTypes.SET_SYS_DICT_PAGE]: ({state}, {page}) => {
            state.page = page;
        },
    },
    getters: {
        records: state => state.page?.records ?? [],
    },
    actions: {
        page: () => {

        },
    },
};
