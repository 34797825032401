// import * as mutationTypes from '@/store/mutations-types';

export const admin = {
    namespaced: true,
    state: () => ({
        title: undefined,
    }),
    // mutations: {
    //     // [mutationTypes.SET_TITLE]: (state, {title}) => {
    //     //     state.title = title;
    //     // },
    // },
    getters: {},
    actions: {},
};