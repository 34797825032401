import * as mutationTypes from "@/store/mutations-types";

export const article = {
    namespaced: true,
    state: () => ({
        /**
         * 所有过审与未过审的文章。主要按是否过审，分两种状态
         */
        undefined: {
            /**
             * 列表页筛选条件
             */
            search: {},
            /**
             * 列表页分页状态
             */
            pageOptions: {},
        },
        /**
         * 未过审文章
         */
        false: {
            search: {},
            pageOptions: {},
        },
        /**
         * 过审文章
         */
        true: {
            search: {},
            pageOptions: {},
        },
    }),
    mutations: {
        [mutationTypes.SET_ARTICLE_SEARCH]: (state, {search, reviewed}) => {
            state[reviewed].search = {...search, reviewed};
        },
        [mutationTypes.SET_ARTICLE_PAGE_OPTIONS]: (state, {pageOptions, reviewed}) => {
            state[reviewed].pageOptions = {...pageOptions};
        },
    },
};
