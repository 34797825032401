import VueRouter from 'vue-router';
import Vue from "vue";
import store from "@/store";
import {staticRoutes} from "@/router/static"
import Router from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: staticRoutes
});
let isToken = true;
router.beforeEach(async (to, from, next) => {
        if (isToken && store.state.routers.routers.length !== 0) {
            //从vuex中获取动态路由
            const accessRoutes = await store.state.routers.routers;
            //动态路由循环解析和添加
            let routes = JSON.parse(JSON.stringify(accessRoutes))
            routes.forEach(v => {
                    const {children, component, meta, path, redirect, name} = v
                    const parse = {meta, path, redirect, name, children}
                    if (children != undefined) {
                        console.log("parentCallChildren", children)
                        parse.children = routerChildren(children);
                    }
                    if (component != undefined) {
                        parse.component = routerCom(component);
                    }
                    console.log(parse)
                    router.addRoute(parse); //添加
                }
            )
            isToken = false //将isToken赋为 false ，否则会一直循环，崩溃
            next({
                ...to, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
                replace: true, // 重进一次, 不保留重复历史
            })
        } else {
            if (to.name == null) {
                next("/404")
            } else {
                // if (to.meta.title) { //判断是否有标题
                //     document.title = to.meta.title //给相应页面添加标题
                //     store.commit('admin/SET_TITLE', {
                //         title: to.meta.title
                //     });
                // }
                document.title = "平山中山医院线上智慧平台"
                next()
            }
        }

    }
)
;

function routerCom(path) {
    return (resolve) => require([`@/views/${path}`], resolve);
}

function routerChildren(child) {
    for (let i = 0; i < child.length; i++) {
        const {children, component, meta, path, redirect, name} = child[i]
        const parse = {meta, path, redirect, name, component, children}
        if (children != undefined) {
            parse.children = routerChildren(children);
        }
        if (component != undefined) {
            parse.component = routerCom(component);
        }
        child[i] = parse
    }
    return child
}

const createRouter = () => new Router({
    scrollBehavior: () => ({y: 0}),
    routes: staticRoutes
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router;
