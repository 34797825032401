import {getTree} from "@/api/menu";

import router from '@/router/index';
import {recursiveSearchSupItemsBy} from "@/utils/utility";

export const navMenu = {
    namespaced: true,
    state: () => ({
        menuTree: [],
        pageHeader: {},
    }),
    getters: {
        openedMenuItems: (state) => {
            let items = recursiveSearchSupItemsBy(state.menuTree, 'component', router.currentRoute.name);
            console.log('items', items);
            return items ?? [];
        },
        openedMenuIds: (state, getters) => {
            return (getters.openedMenuItems ?? []).map(ele => ele.id);
        },
    },
    mutations: {
        ['SET_MENU_TREE']: (state, {menuTree}) => {
            state.menuTree = menuTree;
        },
        calcPageHeader: (state) => {
            let text;
            let icon;

            let items = recursiveSearchSupItemsBy(state.menuTree, 'component', router.currentRoute.name);
            if (items?.length) {
                let item = items[items.length - 1];
                text = item.text;
                icon = item.icon;
            } else {
                let arr = recursiveSearchSupItemsBy(state.menuTree, 'component', router.currentRoute.meta.related);
                let item = arr?.[arr.length - 1];
                text = item?.text ?? router.currentRoute.meta.title;
                icon = item?.icon;
            }
            state.pageHeader = {text, icon};
        },
    },
    actions: {
        getMenuTree: ({commit}) => {
            return getTree()
                .then(r => {
                    commit('SET_MENU_TREE', {menuTree: r.data});
                    return r.data;
                });
        },
    }
};
