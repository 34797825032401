import instance from '@/api/common';
import {stringify} from "qs";

/**
 * 新增。
 * 返回 r.headers.location
 */
export const create = entity => instance.post('/menu/create', entity);

/**
 * 删除单个
 */
export const deleteById = id => instance.delete(`/menu/${id}`);

/**
 * 删除多个
 */
export const deleteByIds = ids => instance.post('/menu/deleteByIds', ids);

/**
 * 更新单个
 */
export const editById = (id, entity) => instance.post(`/menu/${id}`, entity);

/**
 * 分页查询
 */
export const page = (filter, pager) => instance.get('/menu', {
    params: {...pager, ...filter},
    paramsSerializer: stringify,
});

/**
 * 列表查询，按上级 id，返回直接下级列表。
 */
export const list = (filter) => instance.get('/menu', {
    params: {size: -1, ...filter},
    paramsSerializer: stringify,
});

/**
 * 页面左侧栏导航菜单树查询，按用户动态过滤
 */
export const getTree = () => instance.get('/menu/tree');

/**
 * 完整目录树
 */
export const getTreeAll = () => instance.get('/menu/tree-all');

/**
 * 获取单个
 */
export const getById = id => instance.get(`/menu/${id}`);

export const saveMenuAuthorities = (menuId, authorityIds) => instance.put(`/menu/${menuId}/authorities`, authorityIds);

export const listAuthorityIdsById = id => instance.get(`/menu/${id}/authorities/ids`);
