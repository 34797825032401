export const routers = {
    namespaced: true,
    state: () => ({
        routers: "",
    }),
    mutations: {
        routers(state, newsdata) {
            state.routers = newsdata
        },
    },
    actions: {
        routers(context) {
            context.commit('routers')
        },
    },
    getters: {
        routers(state) {
            console.log("getters", state)
            return state.routers
        },

    }
}