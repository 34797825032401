<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.myconfirmcenter{
    width: 240px;
    .el-message-box__btns {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        justify-content: center;
    }
}
</style>