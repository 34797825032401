// 登录
export const SET_LOGIN_STATUS = 'CHANGE_LOGIN_STATUS';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const LOGOUT = 'LOGOUT';

// 标题
export const SET_TITLE = 'SET_TITLE';

// 用户资料
export const SET_PROFILE = 'SET_PROFILE';

// 系统字典
export const SET_SYS_DICT_PAGE = 'SET_SYS_DICT_PAGE';

// 用户管理
export const SET_USER_PAGE = 'SET_USER_PAGE';

//文章管理
export const SET_ARTICLE_SEARCH = 'SET_ARTICLE_SEARCH';

export const SET_ARTICLE_PAGE_OPTIONS = 'SET_ARTICLE_PAGE_OPTIONS';

export const SET_FEEDBACK_SEARCH = 'SET_FEEDBACK_SEARCH';

export const SET_FEEDBACK_PAGE_OPTIONS = 'SET_FEEDBACK_PAGE_OPTIONS';

export const SET_OPENED_MENU_IDS = 'SET_OPENED_MENU_IDS';
