<template>
  <v-skeleton-loader class="fill-height"
                     type="list-item-avatar-three-line, divider, article, actions, divider, table, divider"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "LoadingSkeleton"
}
</script>

<style scoped>

</style>