var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-skeleton-loader', {
    staticClass: "fill-height",
    attrs: {
      "type": "list-item-avatar-three-line, divider, article, actions, divider, table, divider"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }